<style scoped>
div {
  user-select: none;
}
</style>

<template>
  <v-icon ref="icon" :style="style" v-text="icon"></v-icon>
</template>

<script>
import client from "../utils/client";
export default {
  props: { control: Object, isView: Boolean },
  computed: {
    icon() {
      let text = this.control.config.icon || "emoticon";
      return `mdi-${text}`;
    },
    style() {
      let result = JSON.parse(JSON.stringify(this.control.style));
      if (this.control.config.actions.length) result["cursor"] = "pointer";
      return result;
    },
  },
  mounted() {
    this.$refs.icon.$el.onclick = this.onClick;
  },
  beforeDestroy() {
    this.$refs.icon.$el.onclick = null;
  },
  methods: {
    onClick() {
      if (this.isView) {
        for (let i in this.control.config.actions) {
          let action = this.control.config.actions[i];
          if (action.action.type) {
            client.$emit(action.action.type, action.action);
          }
        }
      }
    },
  },
};
</script>
